import React from 'react';
import './App.css';

function App() {
	return (
		<div>
			<div class="header">
				<h1 class="header__title">Haydenz</h1>
			</div>
			<div class="family-tree">
				<div class="family-tree--mom-dad">
					<div class="family-tree__parent family-tree--left">Mark</div>
					<div class="family-tree__parent family-tree--right">Joy</div>
				</div>
				<div class="family-tree--taylor-becca">
					<div class="family-tree__sibling family-tree--left">Taylor & Becca</div>
					<div class="family-tree__child family-tree--right">Waverly</div>
				</div>
				<div class="family-tree--trey-carissa">
					<div class="family-tree__sibling family-tree--left">Trey & Carissa</div>
					<div class="family-tree__child family-tree--right">Milo</div>
				</div>
				<div class="family-tree--dan-carissa">
					<div class="family-tree__sibling family-tree--left">Dan & Carissa</div>
					<div class="family-tree__dog family-tree--right">Kobe</div>
				</div>
				<div class="family-tree--carly">
					<div class="family-tree__sibling family-tree--left">Carly</div>
					<div class="family-tree__dog family-tree--right">Mae</div>
				</div>
				<div class="family-tree--tucker-toby">
					<div class="family-tree__sibling family-tree--left">Tucker & Toby</div>
					<div class="family-tree__dog family-tree--right">Bucket</div>
				</div>

				<div class="family-tree--trevor">
					<div class="family-tree__sibling family-tree--left">Trevor</div>
					<div class="family-tree__child family-tree--right">Ezra</div>
				</div>
				<div class="family-tree--cami">
					<div class="family-tree__sibling family-tree--left">Cami</div>
				</div>
				<div class="family-tree--trent-carolyn">
					<div class="family-tree__sibling family-tree--left">Trent & Carolyn</div>
					<div class="family-tree__child family-tree--right">Gideon</div>
				</div>
			</div>
			<section class="family-cards-section">
				<div class="family-card family-card--photos">
					<div class="family-card__side family-card__side--front family-card__side--front--photos">
						<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 512 512">
							<title>ionicons-v5-e</title>
							<rect
								x="48"
								y="80"
								width="416"
								height="352"
								rx="48"
								ry="48"
								style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: '32px' }}
							/>
							<circle
								cx="336"
								cy="176"
								r="32"
								style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '32px' }}
							/>
							<path
								d="M304,335.79,213.34,245.3A32,32,0,0,0,169.47,244L48,352"
								style={{
									fill: 'none',
									strokeLinecap: 'round',
									strokeLinejoin: 'round',
									strokeWidth: '32px',
								}}
							/>
							<path
								d="M224,432,347.34,308.66a32,32,0,0,1,43.11-2L464,368"
								style={{
									fill: 'none',
									strokeLinecap: 'round',
									strokeLinejoin: 'round',
									strokeWidth: '32px',
								}}
							/>
						</svg>
					</div>
					<div class="family-card__side family-card__side--back family-card__side--back--photos">
						<a href="https://photos.app.goo.gl/vExpmJtneaB3h4o39" class="btn">
							<span>Gideon</span>
						</a>
						<a href="https://photos.app.goo.gl/hrYt9dCW7Sr28skg9" class="btn">
							<span>Milo</span>
						</a>
						<a href="https://photos.app.goo.gl/9V5bx4qCheqn1ZjU7" class="btn">
							<span>Waverly</span>
						</a>
					</div>
				</div>
				<div class="family-card family-card--phones">
					<div class="family-card__side family-card__side--front family-card__side--front--phones">
						<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 512 512">
							<title>ionicons-v5-g</title>
							<path
								d="M451,374c-15.88-16-54.34-39.35-73-48.76C353.7,313,351.7,312,332.6,326.19c-12.74,9.47-21.21,17.93-36.12,14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48,5.41-23.23,14.79-36c13.22-18,12.22-21,.92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9,44,119.9,47,108.83,51.6A160.15,160.15,0,0,0,83,65.37C67,76,58.12,84.83,51.91,98.1s-9,44.38,23.07,102.64,54.57,88.05,101.14,134.49S258.5,406.64,310.85,436c64.76,36.27,89.6,29.2,102.91,23s22.18-15,32.83-31a159.09,159.09,0,0,0,13.8-25.8C465,391.17,468,391.17,451,374Z"
								style={{ fill: 'none', strokeMiterlimit: '10', strokeWidth: '32px' }}
							/>
						</svg>
					</div>
					<div class="family-card__side family-card__side--back family-card__side--back--phones">
						<a href="https://hayden-phones.netlify.com/bill" class="btn">
							<span>Bill</span>
						</a>
						<a href="https://hayden-phones.netlify.com/data" class="btn">
							<span>Data</span>
						</a>
					</div>
				</div>
				<div class="family-card family-card--calendar">
					<div class="family-card__side family-card__side--front family-card__side--front--calendar">
						<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 512 512">
							<title>ionicons-v5-e</title>
							<rect
								x="48"
								y="80"
								width="416"
								height="384"
								rx="48"
								ry="48"
								style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: '32px' }}
							/>
							<path d="M397.82,80H114.18C77.69,80,48,110.15,48,147.2V208H64c0-16,16-32,32-32H416c16,0,32,16,32,32h16V147.2C464,110.15,434.31,80,397.82,80Z" />
							<circle cx="296" cy="232" r="24" />
							<circle cx="376" cy="232" r="24" />
							<circle cx="296" cy="312" r="24" />
							<circle cx="376" cy="312" r="24" />
							<circle cx="136" cy="312" r="24" />
							<circle cx="216" cy="312" r="24" />
							<circle cx="136" cy="392" r="24" />
							<circle cx="216" cy="392" r="24" />
							<circle cx="296" cy="392" r="24" />
							<line
								x1="128"
								y1="48"
								x2="128"
								y2="80"
								style={{
									fill: 'none',
									strokeLinecap: 'round',
									strokeLinejoin: 'round',
									strokeWidth: '32px',
								}}
							/>
							<line
								x1="384"
								y1="48"
								x2="384"
								y2="80"
								style={{
									fill: 'none',
									strokeLinecap: 'round',
									strokeLinejoin: 'round',
									strokeWidth: '32px',
								}}
							/>
						</svg>
					</div>
					<div class="family-card__side family-card__side--back family-card__side--back--calendar">
						<a
							href="https://calendar.google.com/calendar/embed?src=family15286379959160298370%40group.calendar.google.com&ctz=America%2FNew_York"
							class="btn"
						>
							<span>Google Calendar</span>
						</a>
					</div>
				</div>
			</section>
			<div class="color-pallet">
				<div class="square purps">
					<span>purps</span>
				</div>
				<div class="square purps-dark">
					<span>purps dark</span>
				</div>
				<div class="square purps-super-dark">
					<span>purps super dark</span>
				</div>
				<div class="square purps-darkest">
					<span>purps darkest</span>
				</div>
				<div class="square purps-light">
					<span>purps light</span>
				</div>
				<div class="square purps-super-light">
					<span>purps super light</span>
				</div>
				<div class="square purps-lightest">
					<span>purps lightest</span>
				</div>
				<div class="square purps-max">
					<span>purps max</span>
				</div>
			</div>
		</div>
	);
}

export default App;
